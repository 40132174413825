<template>
    <div class="wrap">
        <CancelModal v-if="cancelStatus" />
        <popup-modal v-if="isPopUpModal" :text="popupModalText" @close-modal="onCloseModal" />
        <CancelCheckModal
            v-if="cancelCheckStatus"
            :isRegistCard="isRegistCard"
            :spaceId="spaceId"
            :spaceType="'Car'"
            :spaceInfo="carSpaceInfo"
            @closeCancel="cancelCheckStatus = false"
            @showCancel="cancelStatus = true"
            @deletspace="onDeleteSpace"
        />
        <!--############### PC ###############-->
        <div class="space_pc ov_auto h_100vh">
            <section class="main registration space_insert_wrap fade-up">
                <div class="main_box h_100per">
                    <div class="main_box_title">
                        <h1 class="mb_0px">스페이스 등록</h1>
                        <button type="button" @click="linkMySpace()">
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.81055 7.39387L16.8109 0.393555L18.8105 2.39324L11.8102 9.39355L18.8105 16.3939L16.8109 18.3936L9.81055 11.3932L2.81023 18.3936L0.810547 16.3939L7.81086 9.39355L0.810547 2.39324L2.81023 0.393555L9.81055 7.39387Z"
                                    fill="white"
                                />
                            </svg>
                        </button>
                    </div>

                    <div class="insertspace_form">
                        <h3 class="insertspace_title">차량정보</h3>
                        <div class="pagnation">
                            <span></span>
                            <span style="width: 16%"></span>
                        </div>
                        <ul class="form_info_wrap carinfo">
                            <li class="form_info_label">
                                <label class="form_info_label">차량정보 <span class="form_info_required">*</span> </label>
                            </li>
                            <!-- 차량 브랜드 선택 -->
                            <li class="form_info_data">
                                <div
                                    class="space_select_box"
                                    :class="{ on: isCarBrandOpen, up: isCarBrandOpen }"
                                    @click="isCarBrandOpen = !isCarBrandOpen"
                                    v-click-outside="onCarBrandOutsideClick"
                                >
                                    {{ carSpaceInfo.carBrand }}
                                    <ul class="space_select-box-dropDown" :class="{ on: isCarBrandOpen }">
                                        <li
                                            class="space_select_box_item"
                                            v-for="item in carBrandList"
                                            :key="item.carBrandId"
                                            @click="setCarBrand(item)"
                                        >
                                            <span class="car_brand_name">{{ item.carBrandNm }}</span>
                                        </li>
                                    </ul>
                                </div>
                                <!-- 차종 선택 -->
                                <div class="space_select_box" @click="isCarKind = !isCarKind" v-click-outside="onCarKindOutsideClick">
                                    {{ carSpaceInfo.carKind }}
                                    <ul class="space_select-box-dropDown" :class="{ on: isCarKind }">
                                        <li
                                            class="space_select_box_item"
                                            v-for="item in carKindList"
                                            :key="item.carKindId"
                                            @click="setCarKind(item)"
                                        >
                                            <span class="car_brand_name">{{ item.carKindNm }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <footer class="insert_bottom dp_inlineblock box_contents fade-up">
                    <ul class="insert_bottom_box">
                        <button
                            :disabled="nextDisabled"
                            @click="spaceId !== null ? updateSpaceInfo() : insertSpace()"
                            class="insert_page_btn next"
                        >
                            다음
                        </button>
                    </ul>
                </footer>
            </section>
        </div>
        <!--############### Mobile ###############-->
        <div class="space_mo h_100">
            <div class="back_dim" v-if="isMoCarBrandOpen || isMoCarKind"></div>
            <div class="mo_space_header">
                <div class="mo_header_box">
                    <a>
                        <img src="/media/images/icon_back_24.svg" @click="clickPre()" />
                    </a>
                    <h2>스페이스 등록</h2>
                    <div class="btn_close">
                        <img src="/media/images/icon_close_24.svg" @click="linkMySpace()" />
                    </div>
                </div>
                <div class="pagnation">
                    <span></span>
                    <span style="width: 16%"></span>
                </div>
            </div>
            <section class="main registration regi_car">
                <div class="main_box">
                    <div class="myspace_content_form">
                        <div class="form_text">
                            <h3>차량정보</h3>
                        </div>
                        <ul class="form_info_wrap carinfo">
                            <li class="form_info_label">
                                <label class="form_info_label">차량정보<span class="form_info_required">*</span> </label>
                            </li>
                            <li class="form_info_data">
                                <div
                                    class="space_select_box"
                                    :class="{ on: isMoCarBrandOpen, up: isMoCarBrandOpen }"
                                    @click="isMoCarBrandOpen = !isMoCarBrandOpen"
                                >
                                    {{ carSpaceInfo.carBrand }}
                                </div>
                                <div
                                    class="space_select_box"
                                    :class="{ on: isMoCarKind, up: isMoCarKind }"
                                    @click="isMoCarKind = !isMoCarKind"
                                >
                                    {{ carSpaceInfo.carKind }}
                                </div>
                            </li>
                        </ul>
                        <footer class="insert_bottom">
                            <ul class="insert_bottom_box">
                                <button
                                    :disabled="nextDisabled"
                                    @click="spaceId !== null ? updateSpaceInfo() : insertSpace()"
                                    class="insert_page_btn next"
                                >
                                    다음
                                </button>
                            </ul>
                        </footer>
                        <div class="mo_space_down_box1" :class="{ active: isMoCarBrandOpen }">
                            <ul class="mo_space_down_box_header">
                                <li>
                                    <h2 class="mo_space_down_box1_title">브랜드</h2>
                                </li>
                                <li @click="isMoCarBrandOpen = false">
                                    <svg
                                        data-v-32f9162e=""
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M12.0007 10.5862L16.9507 5.63623L18.3647 7.05023L13.4147 12.0002L18.3647 16.9502L16.9507 18.3642L12.0007 13.4142L7.05072 18.3642L5.63672 16.9502L10.5867 12.0002L5.63672 7.05023L7.05072 5.63623L12.0007 10.5862Z"
                                            fill="white"
                                        ></path>
                                    </svg>
                                </li>
                            </ul>
                            <ul class="mo_space_box_list">
                                <li
                                    class="mo_space_box_item"
                                    v-for="item in carBrandList"
                                    :key="item.carBrandId"
                                    @click="setCarBrand(item)"
                                >
                                    <span class="mo_space_box_text_name">{{ item.carBrandNm }}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="mo_space_down_box1" :class="{ active: isMoCarKind }">
                            <ul class="mo_space_down_box_header">
                                <li>
                                    <h2 class="mo_space_down_box1_title">차량</h2>
                                </li>
                                <li @click="isMoCarKind = false">
                                    <svg
                                        data-v-32f9162e=""
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M12.0007 10.5862L16.9507 5.63623L18.3647 7.05023L13.4147 12.0002L18.3647 16.9502L16.9507 18.3642L12.0007 13.4142L7.05072 18.3642L5.63672 16.9502L10.5867 12.0002L5.63672 7.05023L7.05072 5.63623L12.0007 10.5862Z"
                                            fill="white"
                                        ></path>
                                    </svg>
                                </li>
                            </ul>
                            <ul class="mo_space_box_list">
                                <li
                                    class="mo_space_box_item"
                                    v-for="item in carKindList"
                                    :key="item.carKindId"
                                    @click="setCarKind(item)"
                                >
                                    <span class="mo_space_box_text_name">{{ item.carKindNm }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import CancelCheckModal from '@/components/modal/Space/CancelCheckModal2.vue';
import CancelModal from '@/components/modal/Space/CancelModal.vue';
import VueCookies from 'vue-cookies';
import vClickOutside from 'v-click-outside';
import { defineComponent } from '@vue/composition-api';
import router from '@/router';
import axios from 'axios';
import PopUpModal from '@/components/modal/common/PopUpModal.vue';
import { getCarKindList } from '@/service/api/profileApi';
export default defineComponent({
  data () {
    return {
      carSpaceInfo: {
        carBrand: '브랜드',
        carKind: '차종'
      },
      carBrandList: [],
      carKindList: [],
      cancelStatus: false,
      cancelCheckStatus: false,
      mobility: [],
      brandName: '',
      mobilityInfo: {},
      prevRoute: null,
      disabledInsert: false,
      isRegistCard: true,
      isPopUpModal: false,
      popupModalText: '',
      isCarBrandOpen: false,
      isMoCarBrandOpen: false,
      isCarKind: false,
      isMoCarKind: false,
      spaceId: null
    };
  },
  components: {
    CancelModal,
    CancelCheckModal,
    'popup-modal': PopUpModal
  },

  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    onCarBrandOutsideClick () {
      this.isCarBrandOpen = false;
    },
    onCarKindOutsideClick () {
      this.isCarKind = false;
    },
    async setCarBrand (val) {
      const { carBrandNm, carBrandId } = val;
      await this.$nextTick();
      this.carSpaceInfo.carBrandId = carBrandId;
      this.carSpaceInfo.carBrand = carBrandNm;
      this.carSpaceInfo.carKind = '차종';
      this.setCarKindList();
      if (this.isMoCarBrandOpen) {
        this.isMoCarBrandOpen = false;
      }
    },
    setCarKind (val) {
      const { carKindNm } = val;
      this.carSpaceInfo.carKind = carKindNm;
      if (this.isMoCarKind) {
        this.isMoCarKind = false;
      }
    },
    async setCarKindList () {
      getCarKindList(this.carSpaceInfo.carBrandId)
        .then(res => {
          this.carKindList = res.data.result;
        })
        .catch(() => {
          this.carKindList = [];
        });
    },
    onDeleteSpace (msg) {
      this.isPopUpModal = true;
      this.popupModalText = msg;
    },
    handleError (err) {
      if (err.response) {
        const { data } = err.response;
        const { resultCd, resultMsg } = data;
        if (resultCd === '400' && resultMsg === '삭제된 스페이스입니다.') {
          this.isPopUpModal = true;
          this.popupModalText = resultMsg;
          return false;
        }
      }
    },
    onCloseModal () {
      this.isPopUpModal = false;
      this.$router.push({ name: 'MySpace' });
    },
    checkRegistCard () {
      const userId = VueCookies.get('userId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/product/getMyCardInfo', { userId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            result != null ? (this.isRegistCard = true) : (this.isRegistCard = false);
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    getBrandList () {
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/getMobility', null, { headers })
        .then(res => {
          // console.log(res.data.result);
          this.mobility = res.data.result;
        })
        .catch(err => {
          console.log(err);
        });
    },
    linkMySpace () {
      var spaceId = this.spaceId;
      if (spaceId == null) {
        this.cancelCheckStatus = true;
      } else {
        var aToken = VueCookies.get('aToken');
        var headers = { 'X-AUTH-TOKEN': aToken };
        axios
          .post('/api/profile/checkSubscribe', { spaceId }, { headers })
          .then(res => {
            if (res.data.resultCd === '0000') {
              if (res.data.result === 'S') {
                this.cancelCheckStatus = true;
              } else {
                router.push({ name: 'Player' });
              }
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    insertSpace () {
      this.disabledInsert = true;
      var spaceType = 'Car';
      var userId = VueCookies.get('userId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/createSpaceInfo', { userId, spaceType }, { headers })
        .then(res => {
          var spaceId = res.data.result;
          localStorage.setItem('spaceId', spaceId);
          this.carSpaceInfo.spaceId = spaceId;

          axios
            .post('/api/profile/createCurationInfo', { spaceId }, { headers })
            .then(response => {
              axios
                .post('/api/profile/createPreferMusicList', { spaceId }, { headers })
                .then(res1 => {
                  var spaceInfo = this.carSpaceInfo;
                  axios
                    .post('/api/profile/updateSpaceInfo', spaceInfo, { headers })
                    .then(res => {
                      if (res.data.resultCd === '0000') {
                        this.disabledInsert = false;
                        router.push({ name: 'SpaceInsertCarPurpose' });
                      }
                    })
                    .catch(err => this.handleError(err));
                })
                .catch(err1 => {
                  this.disabledInsert = false;
                  console.log(err1);
                });
            })
            .catch(error => {
              this.disabledInsert = false;
              console.log(error);
            });
        })
        .catch(err => {
          this.disabledInsert = false;
          console.log(err);
        });
    },
    updateSpaceInfo () {
      // 차량 정보 수정
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };
      var spaceInfo = this.carSpaceInfo;

      axios
        .post('/api/profile/updateSpaceInfo', spaceInfo, { headers })
        .then(() => {
          router.push({ name: 'SpaceInsertCarPurpose' });
        })
        .catch(err => {
          console.log(err);
        });
    },
    async getSpaceInfo () {
      // 등록된 차량정보 가져오기
      var spaceId = localStorage.getItem('spaceId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      await axios
        .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
        .then(res => {
          // console.log(res.data.result);

          if (res.data.result.spaceType && res.data.result.spaceType === 'Car') {
            this.carSpaceInfo = res.data.result;

            if (this.carSpaceInfo.brandId != null && this.carSpaceInfo.brandId) {
              this.getBrandInfo();
            }

            if (!this.carSpaceInfo.carBrand) {
              this.carSpaceInfo.carBrand = '브랜드';
            }

            if (!this.carSpaceInfo.carKind) {
              this.carSpaceInfo.carKind = '차종';
            }

            this.getCarBrandList();
          }
        })
        .catch(err => {
          console.log(err);
          this.spaceId = null;
        });
    },
    clickPre () {
      history.back();
    },
    getBrandInfo () {
      var brandId = this.carSpaceInfo.brandId;
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/getBrand', { brandId }, { headers })
        .then(res => {
          // console.log(res.data.result);
          this.brandName = res.data.result.brandNm;
        })
        .catch(err => {
          console.log(err);
        });
    },
    async getCarBrandList () {
      // 차 브랜드 목록 가져오기
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };
      await axios
        .post('/api/profile/getCarBrandList', null, { headers })
        .then(res => {
          // console.log(res.data.result);
          this.carBrandList = res.data.result;

          var carBrandNm = this.carSpaceInfo.carBrand;
          for (var i in this.carBrandList) {
            var kindId = this.carBrandList[i].carBrandNm.indexOf(carBrandNm);
            if (kindId === 0) {
              var kindInfo = this.carBrandList[i];
              this.getCarKindList(kindInfo.carBrandId);
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getCarKindList (item) {
      // 차 종류 목록 가져오기
      var carBrandId = item;
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/getCarKindList', { carBrandId }, { headers })
        .then(res => {
          // console.log(res.data.result);
          this.carKindList = res.data.result;
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  async beforeRouteEnter (to, from, next) {
    await next(vm => {
      vm.prevRoute = from;
    });
  },
  created () {
    if (Object.keys(this.$route.params).length > 0) {
      const { spaceId } = this.$route.params;
      this.spaceId = spaceId;
    } else {
      this.spaceId = localStorage.getItem('spaceId');
    }
  },
  async mounted () {
    this.checkRegistCard();
    this.getBrandList();
    if (this.prevRoute && this.prevRoute.path === '/space/insertCarPurpose') {
      this.spaceId = localStorage.getItem('spaceId');
      await this.getSpaceInfo();
    } else {
      await this.getCarBrandList();

      if (this.spaceId === localStorage.getItem('spaceId') && localStorage.getItem('spaceId') !== null) {
        await this.getSpaceInfo();
      } else {
        this.carSpaceInfo.carKind = '차종';
      }
    }
  },
  computed: {
    nextDisabled: {
      cache: false,
      get () {
        if (this.carSpaceInfo.carBrand !== '브랜드' && this.carSpaceInfo.carKind !== '차종') {
          return false;
        }
        return true;
      }
    }
  }
});
</script>
<style scoped>
@media all and (max-width: 500px) {
  .form_bottom {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 70;
    padding: 16px 5%;
    background: #151515;
    margin: 0 !important;
  }

  .form_bottom button {
    width: 100%;
    height: 54px;
    line-height: 54px;
    font-size: 16px;
  }

  .form_bottom button.opacity3 {
    filter: brightness(30%);
  }

  .myspace_content_form input {
    margin-top: 8px;
  }
}

.space_down_box1 ul li p {
  margin: 0;
}

.space_down_box2 ul li p {
  margin: 0;
}

.space_down_box3 ul li p {
  margin: 0;
}
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
<style src="@/assets/css/insertspace.css" scoped></style>
